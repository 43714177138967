type EarningsIconProps = {
  stroke?: string;
  className?: string;
};

export const EarningsIcon = ({ stroke = '#242424', className }: EarningsIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none" className={className}>
    <path
      d="M24.9633 12.1538H5.04023M24.9633 12.1538L27.6162 16.5751C28.2107 17.566 28.508 18.0616 28.6623 18.5949C28.7992 19.0674 28.8533 19.5602 28.8222 20.0512C28.7872 20.6053 28.6045 21.1533 28.2391 22.2497L27.9433 23.137C27.3835 24.8165 27.1034 25.6562 26.5843 26.2771C26.1258 26.8254 25.5371 27.2498 24.8719 27.5114C24.1187 27.8076 23.2335 27.8076 21.4631 27.8076H8.54049C6.77008 27.8076 5.88487 27.8076 5.13168 27.5114C4.46655 27.2498 3.87775 26.8254 3.41925 26.2771C2.90004 25.6562 2.62011 24.8165 2.06025 23.137L1.7645 22.2497C1.39906 21.1533 1.21636 20.6053 1.18135 20.0512C1.15032 19.5601 1.2044 19.0674 1.3412 18.5948C1.49559 18.0616 1.79286 17.566 2.38739 16.5751L5.04023 12.1538M24.9633 12.1538L25.5571 10.0755C25.8386 9.09015 25.9793 8.59748 25.8688 8.20771C25.772 7.86627 25.551 7.57336 25.2493 7.38643C24.9049 7.17302 24.3926 7.17302 23.3679 7.17302H6.63575C5.61099 7.17302 5.09861 7.17302 4.75421 7.38643C4.45253 7.57336 4.23159 7.86627 4.13472 8.20771C4.02414 8.59748 4.1649 9.09015 4.44643 10.0755L5.04023 12.1538M15.0017 7.17302H9.97017C9.22881 7.17302 8.51781 6.87316 7.99358 6.3394C7.46936 5.80564 7.17485 5.08171 7.17485 4.32687C7.17485 3.57202 7.46936 2.84809 7.99358 2.31434C8.51781 1.78057 9.22881 1.48071 9.97017 1.48071C13.8836 1.48071 15.0017 7.17302 15.0017 7.17302ZM15.0017 7.17302H20.0333C20.7747 7.17302 21.4857 6.87316 22.01 6.3394C22.5342 5.80564 22.8287 5.08171 22.8287 4.32687C22.8287 3.57202 22.5342 2.84809 22.01 2.31434C21.4857 1.78057 20.7747 1.48071 20.0333 1.48071C16.1198 1.48071 15.0017 7.17302 15.0017 7.17302Z"
      stroke={stroke}
      strokeWidth="2.13462"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
