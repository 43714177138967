import React from 'react';
import { TNotificationCardPropsProvider } from '../types';
import { getCommonPropsForPropsProvider } from './getCommonPropsForPropsProvider';
import { NotificationCardTitleWithInitiator } from '../NotificationCardTitleWithInitiator';
import { ShieldIcon } from '../../Icons/ShieldIcon';

export const membershipExpiredPropsProvider: TNotificationCardPropsProvider = ({
  notification,
  sendEvent,
  closeAsidePanel,
}) => {
  const { createdAt, onClick } = getCommonPropsForPropsProvider({
    notification,
    sendEvent,
    closeAsidePanel,
  });
  return {
    createdAt,
    onClick,
    title: (
      <NotificationCardTitleWithInitiator
        content={
          <div>
            Your membership role <span className="font-bold">RoleName</span> in{' '}
            <span className="font-bold">TerritoryName</span> has expired. Renew your membership role to regain access to
            your access and permissions.
          </div>
        }
      />
    ),
    leftAdornment: (
      <div className="flex-shrink-0 w-8 h-8 bg-t2-error-lightRed rounded-full flex items-center justify-center text-t2-error-darkRed">
        <ShieldIcon className="!w-5 !h-5" />
      </div>
    ),
    // TODO: add membership title to url
    url: 't/[territorySlug]/manage-my-memberships?[membershipTitle]',
  };
};
