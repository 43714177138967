/* it's a copy of NotificationType from apps/keystone/src/schema/Notification/constants.ts */
export enum NotificationType {
  NEW_COMMENT_ON_POST = 'NEW_COMMENT_ON_POST',
  NEW_COMMENT_ON_PROMPT = 'NEW_COMMENT_ON_PROMPT',
  NEW_REPLY_TO_COMMENT = 'NEW_REPLY_TO_COMMENT',
  NEW_REPLY_TO_PROMPT = 'NEW_REPLY_TO_PROMPT',
  NEW_POST_LIKE = 'NEW_POST_LIKE',
  NEW_PROMPT_LIKE = 'NEW_PROMPT_LIKE',
  NEW_COMMENT_LIKE = 'NEW_COMMENT_LIKE',
  NEW_POST_IN_TERRITORY = 'NEW_POST_IN_TERRITORY',
  NEW_PROMPT_IN_TERRITORY = 'NEW_PROMPT_IN_TERRITORY',
  NEW_REPLY_TO_QUOTE = 'NEW_REPLY_TO_QUOTE',
  NEW_COLLECT_TRANSACTION_STATUS = 'NEW_COLLECT_TRANSACTION_STATUS',
  MEMBERSHIP_EXPIRED = 'MEMBERSHIP_EXPIRED',
  DISTRIBUTION = 'DISTRIBUTION',
}

export const NOTIFICATIONS_INITIAL_ITEMS_COUNT = 40;
export const NOTIFICATIONS_NEXT_ITEMS_COUNT = 10;

export enum AnalyticsNotificationType {
  NEW_COMMENT_ON_POST = 'comment_on_post',
  NEW_COMMENT_ON_PROMPT = 'comment_on_prompt',
  NEW_REPLY_TO_COMMENT = 'comment_reply',
  NEW_REPLY_TO_PROMPT = 'prompt_reply',
  NEW_POST_LIKE = 'like_post',
  NEW_PROMPT_LIKE = 'like_prompt',
  NEW_COMMENT_LIKE = 'like_comment',
  NEW_POST_IN_TERRITORY = 'new_territory_post',
  NEW_PROMPT_IN_TERRITORY = 'new_prompt',
  NEW_REPLY_TO_QUOTE = 'quote_reply',
  MEMBERSHIP_EXPIRED = 'membership_expired',
  DISTRIBUTION = 'distribution',
}

export enum NotificationKind {
  Default = 'Default',
  Error = 'Error',
}
