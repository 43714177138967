type TShieldIconProps = {
  className?: string;
};

export const ShieldIcon = ({ className }: TShieldIconProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12C20 16.7714 14.9408 20.2781 12.8689 21.514C12.3283 21.8366 11.6717 21.8366 11.1311 21.514C9.05923 20.2781 4 16.7714 4 12V6.5C4 5.39543 4.90411 4.50891 5.98411 4.27721C7.887 3.86898 9.63003 2.98214 10.7445 2.31978C11.5086 1.86564 12.4914 1.86564 13.2555 2.31978C14.37 2.98214 16.113 3.86898 18.0159 4.27721C19.0959 4.50891 20 5.39543 20 6.5V12Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
    <g clipPath="url(#clip0_2634_34840)">
      <path
        d="M10 13.5C12.4374 13.5 13.5 12.4745 13.5 10C13.5 12.4745 14.5552 13.5 17 13.5C14.5552 13.5 13.5 14.5552 13.5 17C13.5 14.5552 12.4374 13.5 10 13.5Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
      <path
        d="M7 9.25C8.56688 9.25 9.25 8.59076 9.25 7C9.25 8.59076 9.92834 9.25 11.5 9.25C9.92834 9.25 9.25 9.92834 9.25 11.5C9.25 9.92834 8.56688 9.25 7 9.25Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.75"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2634_34840">
        <rect width="12" height="12" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);
