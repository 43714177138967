import { gql } from '@apollo/client';

export const getAuthenticatedItemQuery = gql`
  query authenticatedItem {
    authenticatedItem {
      __typename
      ... on User {
        ...AuthenticatedItemFields
      }
    }
  }
`;
