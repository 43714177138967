import { Notification } from 't2-graphql-types';
import Link from 'next/link';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Typo from '../Typography';
import Spinner from '../Spinner/Spinner';
import { NotificationCardContainer } from './NotificationCardContainer';
import SettingsIcon from '../Icons/SettingsIcon';
import { SettingsSubpage } from '../UserSettings/constants';
import { getSettingsPageUrl } from '../../services/pagesUrl/getSettingsPageUrl';
import Button from '../Button/Button';
import { ArrowRight } from '../Icons';
import { isBrowser } from '../../services/browser/isBrowser';

type TNotificationsListProps = {
  notifications: Notification[];
  notificationsCount: number;
  loading: boolean;
  fetchMoreNotifications: () => void;
  isOpen: boolean;
};

const Loader = (
  <div className="my-5 text-center">
    <Spinner size={8} />
  </div>
);

const Footer = ({
  fetchMoreNotifications,
  hasMoreNotificationsToLoad,
  isLoading,
}: {
  fetchMoreNotifications: () => void;
  hasMoreNotificationsToLoad: boolean;
  isLoading: boolean;
}) => {
  if (!hasMoreNotificationsToLoad) {
    return null;
  }

  return (
    <Button
      variant="ghost"
      onClick={fetchMoreNotifications}
      className="!py-[6px] mt-8 mx-auto !text-base"
      outlined
      rounded="2xl"
    >
      <div className="flex min-w-20 gap-2 justify-center items-center">
        {isLoading ? (
          <div className="h-6">
            <Spinner color="gray" size={10} />
          </div>
        ) : (
          <>
            Load more
            <ArrowRight className="w-4 h-4 rotate-90" />
          </>
        )}
      </div>
    </Button>
  );
};

const useIsomorphicLayoutEffect = isBrowser() ? useLayoutEffect : useEffect;

export const NotificationsList = ({
  notifications,
  notificationsCount,
  loading,
  isOpen,
  fetchMoreNotifications,
}: TNotificationsListProps) => {
  const hasMoreNotificationsToLoad = notificationsCount > notifications?.length;
  const title = (
    <div className="flex items-center justify-between px-5 mt-10 mb-3">
      <Typo tag="div" className="text-[20px] md:text-[22px] font-bold">
        Notifications
      </Typo>
      <Link href={getSettingsPageUrl(SettingsSubpage.notifications)}>
        <SettingsIcon className="w-4 h-4 stroke-t2-neutral-grey07 hover:stroke-t2-primary-brandColor" />
      </Link>
    </div>
  );

  const notificationsListRef = useRef<null | HTMLDivElement>(null);
  useIsomorphicLayoutEffect(() => {
    if (isOpen) {
      notificationsListRef?.current?.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, [isOpen]);

  if (loading && notificationsCount === 0) {
    return Loader;
  }

  if (!loading && notificationsCount === 0) {
    return (
      <div className="flex flex-col w-full">
        {title}
        <div className="flex flex-col place-content-center h-full bottom-[50px] text-center text-black-700">
          <Typo tag="div" className="py-3.5 text-[16px] font-bold leading-6">
            You’re all caught up!
          </Typo>
          <Typo tag="div" className="text-[14px] leading-[22px] text-[#bdbdbd]">
            We'll let you know when there’s something new.
          </Typo>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full w-full overflow-hidden">
      {title}
      <div className="flex-1 overflow-y-auto" ref={notificationsListRef}>
        <div>
          <Typo variant="small-p" className="ml-5 font-semibold text-t2-neutral-grey06">
            Newest
          </Typo>
          {notifications.slice(0, 3).map((notification, index) => (
            <NotificationCardContainer key={notification.id || index} notification={notification} />
          ))}
        </div>

        {notifications.length > 3 && (
          <div className=" mt-5 md:mt-6">
            <Typo variant="small-p" className="ml-5 font-semibold text-t2-neutral-grey06">
              Earlier
            </Typo>
          </div>
        )}

        {notifications.slice(3).map((notification, index) => (
          <NotificationCardContainer key={notification.id || index} notification={notification} />
        ))}

        <Footer
          hasMoreNotificationsToLoad={hasMoreNotificationsToLoad}
          fetchMoreNotifications={fetchMoreNotifications}
          isLoading={loading}
        />
      </div>
    </div>
  );
};
